import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoading } from '../../providers/LoadingProvider';

export function Loader({ hideBackground = false, forceLoading = false }) {
  const { isLoading } = useLoading();
  const opacity = hideBackground ? 1 : 0.3;

  return (
    <Backdrop
      sx={{ background: 'rgba(255, 255, 255, .4)', opacity, zIndex: (theme) => theme.zIndex.tooltip + 1 }}
      open={isLoading || forceLoading}
    >
      <CircularProgress />
    </Backdrop>
  );
}