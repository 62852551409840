import * as React from 'react';
import { createContext, useCallback, useContext, useState, ReactNode } from 'react';

const LoadingContext = createContext<{
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => any;
}>({
  isLoading: false,
  setIsLoading: (isLoading: boolean) => {},
});

export function LoadingProvider({ children }: { children: ReactNode }) {
  const [counter, setCounter] = useState(0);

  const setIsLoading = useCallback((isLoading: boolean) => {
    if (isLoading) {
      setCounter(counter => counter + 1);
    } else {
      setCounter(counter => counter - 1);
    }
  }, [setCounter]);

  const value = React.useMemo(() => ({ isLoading: counter > 0, setIsLoading }), [counter, setIsLoading]);
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
  const Loading = useContext(LoadingContext);
  return Loading;
}
