import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createAppTheme } from './theme';
import { AppRoutes } from './routes/AppRoutes';
import { Loader } from './components/Loader';
import { AuthProvider } from './providers/AuthProvider';
import { LoadingProvider } from './providers/LoadingProvider';

const lightTheme = createAppTheme('light');
const darkTheme = createAppTheme('dark');

const theme_key = 'LAUNCH_PORTAL_THEME';

const getStoredTheme = (): 'light'|'dark' => {
  const theme = localStorage.getItem(theme_key);
  return theme === 'light' ? 'light' : 'dark';
}

const setStoredTheme = (theme: 'light' | 'dark') => {
  localStorage.setItem(theme_key, theme);
}

export function App() {
  const [theme, setTheme] = React.useState(getStoredTheme());

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';

    setStoredTheme(newTheme);
    setTheme(newTheme);
  }

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />
      <LoadingProvider>
        <Loader />
        <AuthProvider>
          <AppRoutes toggleTheme={toggleTheme} theme={theme} />
        </AuthProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}