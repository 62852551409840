import { Amplify, Auth } from 'aws-amplify';
import { REGION, USER_POOL_ID, USER_POOL_CLIENT_ID } from '../constants/env';

const HACK_PASSWORD = 'H@ck_To_W0rk_With_Amplify';
const CREATE_CHALLENGE_ERROR_PREFIX = 'CreateAuthChallenge failed with error ';

const LOCALHOST_HOSTNAME = 'localhost';

export class AuthService {
  private static tempUser = null;

  static init() {
    const hostname = globalThis.location.hostname;

    Amplify.configure({
      Auth: {
        region: REGION,
        userPoolId: USER_POOL_ID,
        userPoolWebClientId: USER_POOL_CLIENT_ID,
        cookieStorage: {
          domain: hostname,
          secure: hostname !== LOCALHOST_HOSTNAME,
        },
      },
    });
  }

  private static async signUp(email: string) {
    const params = {
      username: email,
      password: HACK_PASSWORD,
    };

    try {
      await Auth.signUp(params);
    } catch (err) {
      console.info('Already signed up');
    }
  }

  public static async requestCode(email: string) {
    await AuthService.signUp(email);

    try {
      AuthService.tempUser = await Auth.signIn(email);
    } catch (e) {
      const message = (e as Error).message.split(CREATE_CHALLENGE_ERROR_PREFIX).slice(-1)[0];
      throw new Error(message);
    }
  }

  public static async verifyCode(code: string) {
    if (!AuthService.tempUser) {
      throw new Error('User has not requested a code');
    }

    try {
      await Auth.sendCustomChallengeAnswer(AuthService.tempUser, code);
    } catch (e) {
      throw new Error('The code is incorrect or expired. Please try again to request a code');
    }

    try {
      await Auth.currentSession();
    } catch {
      throw new Error('Code is incorrect');
    }
  }

  public static async getToken() {
    try {
      await Auth.currentSession();
    } catch {
      console.error('Session is invalid');
      throw new Error('Session is invalid');
    }
    
    const currentUser = await Auth.currentAuthenticatedUser();
    return currentUser.signInUserSession.accessToken.jwtToken as string;
  }

  public static async getRole() {
    const currentUser = await Auth.currentAuthenticatedUser();
    return currentUser?.attributes?.['custom:salesRole'] || null;
  }

  public static async signOut() {
    const currentUser = await Auth.signOut();
    return currentUser;
  }
}
