import * as React from 'react';
import { useOutlet } from 'react-router-dom';
import { StagingBanner } from '../components/StagingBanner';

export function BaseLayout() {
  const outlet = useOutlet();

  return (
    <>
      <StagingBanner />
      <React.Suspense children={outlet} />
    </>
  );
}