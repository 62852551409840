import { createTheme } from '@mui/material/styles';
// import * as colors from '@mui/material/colors';

export const createAppTheme = (mode: 'dark'|'light') => createTheme({
  palette: {
    // mode: 'dark',
    mode,
    // primary: {
    //   main: colors.grey[50]
    // },
    // secondary: {
    //   main: '#2e42dc',
    // },
    // text: {
    //   primary: '#000000',
    // },
  },
});
