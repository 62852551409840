import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Switch from '@mui/material/Switch';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AuthService } from '../services/Auth';
import { useAuth } from '../providers/AuthProvider';
import { useLoading } from '../providers/LoadingProvider';
import { StagingBanner } from '../components/StagingBanner';

const routesMap = {
  '/clients': 'Clients',
  '/team': 'Team',
  '/journey': 'Analytics',
  // '/daisi': 'DAISI', //@todo uncomment when DAISI is ready
};

const routePaths = Object.keys(routesMap);

export function Header({ toggleTheme, theme }: { toggleTheme: () => void, theme: 'light' | 'dark' }) {
  const navigate = useNavigate();

  const location = useLocation();
  const routePath = routePaths.find((path) => location.pathname.startsWith(path));

  const { setIsLoading } = useLoading();
  const auth = useAuth();

  const logout = React.useCallback(() => {
    setIsLoading(true);
    AuthService.signOut().then(() => auth.signout(() => {
      setIsLoading(false);
      navigate('/login')
    }));
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <StagingBanner />
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <img
            src="/space-logo.png"
            alt="Logo"
            loading="lazy"
            width={40}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, padding: '0 16px' }}>
            Launch Portal
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex', marginLeft: 'auto', alignItems: 'center' } }}>
            <Tabs
              value={routePath}
              textColor="inherit"
              indicatorColor="secondary"
            >
              {
                routePaths.map((path) => (
                  <Tab
                    key={path}
                    value={path}
                    label={routesMap[path as keyof typeof routesMap]}
                    onClick={() => navigate(path)}
                  />
                ))
              }
              <IconButton onClick={logout} sx={{ padding: '8px 12px'}}>
                <LogoutIcon />
              </IconButton>
              <Switch checked={theme === 'dark'} onClick={toggleTheme} size="small" sx={{ marginTop: '12px' }} />
            </Tabs>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none', marginLeft: 'auto' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {
                routePaths.map((path) => (
                  <MenuItem key={path} onClick={() => { setAnchorElNav(null); navigate(path); }}>
                    <Typography textAlign="center">{routesMap[path as keyof typeof routesMap]}</Typography>
                  </MenuItem>
                ))
              }
              <MenuItem key="logout" onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              <MenuItem key="switch-theme" onClick={toggleTheme}>
                <Typography textAlign="center">Swith Theme</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}