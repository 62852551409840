import * as React from 'react';
import { useOutlet } from 'react-router-dom';
import { Header } from './Header';

export function AppLayout({ toggleTheme, theme }: { toggleTheme: () => void, theme: 'light' | 'dark' }) {
  const outlet = useOutlet();

  return (
    <>
      <Header toggleTheme={toggleTheme} theme={theme} />
      <main style={{ paddingBottom: '30px' }}>
        <React.Suspense children={outlet} />
      </main>
    </>
  );
}