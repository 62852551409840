import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useState, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { AuthService } from '../services/Auth';
import { Loader } from '../components/Loader';

const AuthContext = createContext<{
  token: string|null|undefined;
  role: string|null|undefined;
  signin: (token: string|null|undefined, callback?: () => any) => any;
  signout: (callback?: () => any) => any;
}>({
  token: undefined,
  role: null,
  signin: (token: string|null|undefined, callback?: () => any) => {},
  signout: (callback?: () => any) => {},
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<string|null|undefined>(undefined);
  const [role, setRole] = useState<any>(null);
  const location = useLocation();

  useEffect(() => {
    AuthService.init();
  }, []);

  const signin = useCallback((newToken: string|null|undefined, callback?: () => any) => {
    setToken(newToken);
    callback?.();
  }, []);

  const signout = useCallback((callback?: () => any) => {
    setToken(null);
    callback?.();
  }, []);

  const value = { token, signin, signout, role };

  useEffect(() => {
    AuthService.getToken()
      .then(newToken => {
        setToken(newToken);
      })
      .catch(() => {
        setToken(null);
      });

    AuthService.getRole()
      .then(role => {
        setRole(role);
      })
      .catch(() => {
        setRole(null);
      });
  });

  if (token === undefined) {
    return <Loader hideBackground forceLoading />;
  }

  if (token === null && location.pathname !== '/login') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (token && location.pathname === '/login') {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);
  return auth;
}
